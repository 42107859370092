<template>
  <div class="mb-7.5 lg:mb-12">
    <h3
      class="tg-mobile-header-3 lg:tg-desktop-header-3 mb-5"
      v-text="$t('make_an_offer')"
    />
    <div class="rounded-lg shadow-card divide-y divide-gray-e9">
      <div class="p-5 lg:px-7.5">
        <FormulateInput
          v-model.number="bidAmount"
          type="number"
          name="amount"
          step="any"
          min="1.00"
          :label="$t('how_much_do_you_want_to_offer')"
          :show-required-label="true"
          :help="`${$t('bid_finance_min_value')}\n${$t('bid_offer_validity')}.`"
          validation="bail|required|number"
          :input-class="['pr-12']"
          :element-class="['flex']"
          outer-class="my-1 w-full max-w-xs"
          :help-class="['whitespace-pre-wrap']"
          data-lpignore="true"
          @keydown="blockPeriodInput"
        >
          <template #suffix>
            <div
              class="transform -translate-x-full bg-brand-primary inline-flex items-center justify-center w-10 h-10 rounded-r-md text-white"
            >
              €
            </div>
          </template>
        </FormulateInput>
      </div>
      <transition name="fade" mode="out-in">
        <PropertyBidConditions :amount="bidAmount" />
      </transition>
      <div class="divide-y-0.5 divide-gray-e9">
        <div class="grid grid-cols-2 divide-x-0.5 divide-gray-e9 break-words">
          <span
            class="p-5 tg-mobile-body-small-bold lg:tg-desktop-body-bold"
            v-text="$t('your_offer')"
          />
          <span class="p-5">{{ currency(bidAmount) }}</span>
        </div>
        <div class="grid grid-cols-2 divide-x-0.5 divide-gray-e9">
          <a
            class="p-5 tg-mobile-link-small lg:tg-desktop-body-bold link underline"
            @click="serviceCostModal = true"
          >
            {{ $t('service_fee') }} <i class="far fa-info-circle" />
          </a>
          <span class="p-5">{{ currency(serviceCost) }}</span>
          <BaseModal
            :show-modal="serviceCostModal"
            icon="fa-info-circle"
            @close="serviceCostModal = false"
          >
            <template #message>
              {{ $t('service_costs_are') }}
              <b>{{ currency(2499) }} incl. BTW.</b>
              {{ $t('modal_service_cost_message') }}
            </template>
          </BaseModal>
        </div>
        <div class="grid grid-cols-2 divide-x-0.5 divide-gray-e9">
          <a
            class="p-5 tg-mobile-link-small lg:tg-desktop-body-bold link underline"
            @click="totalCostModal = true"
          >
            {{ $t('total_cost') }} <i class="far fa-info-circle" />
          </a>
          <span class="p-5">{{ currency(totalCost) }}</span>
          <BaseModal
            :show-modal="totalCostModal"
            icon="fa-info-circle"
            :message="$t('modal_total_cost_message')"
            @close="totalCostModal = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyBidConditions from '@/components/PropertyBidConditions';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { currency, blockPeriodInput } from '@/helpers';

export default {
  name: 'PropertyBidForm',
  components: {
    PropertyBidConditions
  },
  data() {
    return {
      serviceCostModal: false,
      totalCostModal: false
    };
  },
  computed: {
    ...mapState('property', ['amount', 'serviceCost']),
    ...mapGetters('property', ['totalCost']),
    bidAmount: {
      get() {
        return this.amount;
      },
      set(value) {
        this.SET_PROPERTY_BID_AMOUNT(value);
      }
    }
  },
  methods: {
    currency,
    blockPeriodInput,
    ...mapMutations('property', ['SET_PROPERTY_BID_AMOUNT'])
  }
};
</script>
