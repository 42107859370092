<template>
  <transition name="fade" mode="out-in">
    <BaseIconMessage
      v-if="bidStatus === 2"
      key="bidPending"
      :border="false"
      icon="fa-rocket-launch"
      :message="$t('bid_message_pending')"
    />
    <BaseIconMessage
      v-else-if="bidStatus === 4"
      key="bidSuccess"
      :border="false"
      icon="fa-check-circle"
      :message="$t('bid_message_success')"
      text-color="text-notification-success"
    />
    <FormulateForm
      v-else
      key="bidForm"
      #default="{ isLoading }"
      v-model="bid"
      name="registerBid"
      @submit="submit"
    >
      <PropertyBidForm />
      <PropertyBuyers />
      <FormulateInput
        type="toggle"
        name="accept_terms"
        validation="required"
        :validation-messages="{
          required: $t('validation_required_terms')
        }"
        label-position="after"
        outer-class="my-8"
      >
        <template #label>
          <p class="tg-mobile-body-small-bold lg:tg-desktop-body">
            {{ $t('i_agree_with_the') }}
            <a
              href="https://www.dewaele.com/nl/disclaimer"
              target="_blank"
              class="link underline"
            >
              {{ $t('purchase_conditions') }}
            </a>
            {{ $t('and_the') }}
            <a
              :href="$t('privacy_policy_property_link')"
              target="_blank"
              class="link underline"
            >
              {{ $t('privacy_policy') }}
            </a>
          </p>
        </template>
      </FormulateInput>
      {{ $t('bid_pre_submit_message') }}
      <FormulateErrors />
      <FormulateInput
        type="submit"
        :help="formErrorHelp[0]"
        :disabled="formErrorHelp.length > 0 || isLoading"
        :input-class="['w-full lg:w-auto']"
      >
        <i
          :class="[
            'far mr-2',
            isLoading ? 'fa-spinner-third animate-spin' : 'fa-gavel'
          ]"
        />
        {{ $t('submit_offer') }}
      </FormulateInput>
    </FormulateForm>
  </transition>
</template>

<script>
import PropertyBidForm from '@/components/PropertyBidForm';
import PropertyBuyers from '@/components/PropertyBuyers';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PropertyBid',
  components: {
    PropertyBidForm,
    PropertyBuyers
  },
  data() {
    return {
      propertyId: this.$route.params.propertyId,
      bid: {}
    };
  },
  computed: {
    ...mapState('property', ['propertyBuyers', 'propertyBidStatus']),
    bidStatus() {
      return this.propertyBidStatus[this.propertyId];
    },
    formErrorHelp() {
      let errors = [];
      if (!this.propertyBuyers.length) {
        errors.push(this.$t('validation_required_buyers'));
      }
      if (!this.bid.accept_terms) {
        errors.push(this.$t('please_accept_terms'));
      }
      return errors;
    }
  },
  methods: {
    ...mapActions('property', ['submitBid']),
    submit(data) {
      const bid = {
        property_id: this.propertyId,
        ...data
      };
      delete bid.accept_terms;

      let model = {};

      return this.submitBid(bid)
        .then(() => {
          // Promise resolved but the bidStatus might not be 4 yet, hence bid not finished.
          model = {
            type: 'loading',
            message: this.$t('bid_message_pending')
          };
        })
        .catch(error => {
          model = {
            type: 'error',
            messageBold: this.$t('could_not_place_bid'),
            message: error.message
          };
          this.$formulate.handle(error, 'registerBid');
        })
        .finally(() => {
          this.$store.dispatch('snackbar/show', model);
        });
    }
  }
};
</script>
