<template>
  <div>
    <h3
      class="tg-mobile-header-3 lg:tg-desktop-header-3 mb-5"
      v-text="$t('who_is_the_buyer')"
    />
    <transition name="fade" mode="out-in">
      <div
        v-if="propertyBuyers.length"
        class="rounded-lg divide-y-0.5 divide-gray-e9 shadow-card"
      >
        <div
          v-for="(buyer, index) in propertyBuyers"
          :key="index"
          class="flex divide-x-0.5 divide-gray-e9"
        >
          <div
            class="flex-grow px-5 py-3 lg:p-5 tg-mobile-body-small-bold lg:tg-desktop-body-bold"
          >
            <span
              v-if="buyer.company_name"
              class="whitespace-pre-wrap"
              v-text="buyer.company_name + '\n'"
            />
            <span :class="{ 'font-normal text-gray-cc': buyer.company_name }">
              {{ buyer.first_name }} {{ buyer.last_name }}
            </span>
          </div>
          <button
            type="button"
            class="flex px-5 lg:p-5 items-center opacity-84 hover:opacity-100"
            @click="editBuyer(buyer, index)"
          >
            <i class="far fa-pencil text-notification-success" />
          </button>
          <button
            type="button"
            class="flex px-5 lg:p-5 items-center opacity-84 hover:opacity-100"
            @click="deleteBuyer(buyer, index)"
          >
            <i class="far fa-trash text-notification-danger" />
          </button>
        </div>
      </div>
      <BaseIconMessage
        v-else
        icon="fa-user-astronaut"
        :message="$t('modal_message_no_buyers')"
      />
    </transition>
    <PropertyBuyersAddEdit
      :selected-buyer="buyerPayload"
      @close="buyerPayload = { edit: false }"
    />
  </div>
</template>

<script>
import PropertyBuyersAddEdit from '@/components/PropertyBuyersAddEdit';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'PropertyBuyers',
  components: {
    PropertyBuyersAddEdit
  },
  data() {
    return {
      buyerPayload: {
        edit: false
      }
    };
  },
  computed: {
    ...mapState('property', ['propertyBuyers'])
  },
  methods: {
    ...mapMutations('property', ['DELETE_PROPERTY_BUYER']),
    deleteBuyer(buyer, index) {
      this.DELETE_PROPERTY_BUYER(index);

      this.$store.dispatch('snackbar/show', {
        type: 'success',
        messageBold: buyer.company_name || buyer.first_name,
        message: this.$t('has_been_removed')
      });
    },
    editBuyer(buyer, buyerIndex) {
      this.buyerPayload.edit = true;
      this.buyerPayload.index = buyerIndex;
      this.buyerPayload.buyer = buyer;
    }
  }
};
</script>
