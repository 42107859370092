<template>
  <FormulateForm
    v-if="amount > 1"
    #default="{ hasErrors }"
    class="px-5 lg:px-7.5"
    @submit="submit"
  >
    <FormulateInput
      v-model="conditions"
      type="toggle"
      name="conditions"
      label-position="after"
    >
      <template #label>
        <p class="tg-mobile-body-small-bold lg:tg-desktop-body">
          {{ $t('i_wish_to_make_an_offer_with') }}
          <a class="link underline" @click="conditionModal = true">
            {{ $t('conditions_financing') }}
          </a>
        </p>
      </template>
    </FormulateInput>

    <BaseModal
      :show-modal="conditionModal"
      icon="fa-info-circle"
      :message="$t('condition_modal_text')"
      @close="conditionModal = false"
    />

    <template v-if="conditions">
      <div class="relative mb-6 shadow-card rounded-lg">
        <button
          type="button"
          class="absolute right-0 h-10 w-10 transform -translate-y-1/2 flex-shrink-0 bg-white shadow-md rounded-full hover:shadow-lg active:shadow-md"
          @click="toggleconditionsForm"
        >
          <i class="far fa-pencil text-notification-success" />
        </button>
        <div class="relative flex flex-col divide-y-0.5">
          <div
            v-for="(condition, index) in conditionDetails"
            :key="index"
            class="px-5 py-2.5"
          >
            <span
              class="tg-mobile-body-small-bold lg:tg-desktop-body-bold text-brand-primary"
            >
              {{ condition.text }}
            </span>
            <br />
            <span class="tg-mobile-body-small lg:tg-desktop-body">
              {{ condition.value }}
            </span>
          </div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div
          v-show="conditionsFormShow"
          class="w-full h-full fixed inset-0 z-40 grid place-items-center bg-overlay overflow-auto"
        >
          <div class="w-full max-w-lg bg-white rounded-lg cursor-auto">
            <div
              class="flex justify-between items-baseline p-5 lg:px-7.5 border-b border-gray-e9"
            >
              <h4
                class="tg-mobile-header-4 lg:tg-desktop-header-4"
                v-text="$t('suspensive_conditions_of_financing')"
              />
              <button type="button" class="-m-4" @click="hideConditionsForm">
                <i class="p-4 far fa-times" />
              </button>
            </div>
            <div class="p-5 lg:p-7.5">
              <FormulateInput
                id="conditionsInput"
                v-model.number="conditions_amount"
                type="number"
                name="conditions_amount"
                step="any"
                min="1.00"
                :label="$t('amount_for_which_funding')"
                :show-required-label="true"
                :validation="[
                  ['bail'],
                  ['required'],
                  ['number'],
                  ['min', 1],
                  ['max', amount - 1]
                ]"
                :validation-name="$t('this_field')"
                :input-class="['pr-12 ']"
                :element-class="['mt-3 flex max-w-sm']"
                outer-class="mb-5"
                data-lpignore="true"
                @keydown="blockPeriodInput"
              >
                <template #suffix>
                  <div
                    class="transform -translate-x-full bg-brand-primary inline-flex items-center justify-center w-10 h-10 rounded-r-md text-white"
                  >
                    €
                  </div>
                </template>
              </FormulateInput>
              <div class="w-auto">
                <label
                  for="daysInput"
                  class="tg-mobile-label lg:tg-desktop-label"
                  v-text="$t('period_within_credit_application')"
                />
                <div class="relative flex">
                  <FormulateInput
                    type="button"
                    :disabled="conditions_term < 12"
                    :input-class="['absolute rounded-full shadow-none']"
                    @click="decrementDays"
                  >
                    <i class="far fa-minus" />
                  </FormulateInput>
                  <FormulateInput
                    id="daysInput"
                    ref="daysInput"
                    v-model.number="conditions_term"
                    type="text"
                    name="conditions_term"
                    inputmode="numeric"
                    validation="bail|required:trim|number|min:11|max:20"
                    :validation-name="$t('this_field')"
                    error-behavior="live"
                    :input-class="[
                      'mb-2 px-16 tg-mobile-body-bold text-center border-none rounded-full shadow-card'
                    ]"
                    data-lpignore="true"
                  />
                  <FormulateInput
                    type="button"
                    :disabled="conditions_term > 19"
                    :input-class="[
                      'absolute transform -translate-x-full rounded-full shadow-none'
                    ]"
                    @click="incrementDays"
                  >
                    <i class="far fa-plus" />
                  </FormulateInput>
                </div>
              </div>
              <FormulateInput
                type="submit"
                :disabled="hasErrors"
                :input-class="['w-full lg:w-auto']"
              >
                <i class="far fa-check mr-2" />
                {{ $t('instellen') }}
              </FormulateInput>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </FormulateForm>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { currency, addDaysText, blockPeriodInput } from '@/helpers';

export default {
  name: 'PropertyBidConditions',
  props: {
    amount: {
      type: [Number, String], // String in case of no input i.e. '' value
      required: true
    }
  },
  data() {
    return {
      conditionModal: false,
      conditionsFormShow: false,
      conditions: false,
      conditions_amount: null,
      conditions_term: null,
      conditionsSubmitted: false
    };
  },
  computed: {
    ...mapState('property', ['propertyConditions']),
    conditionDetails() {
      return [
        {
          text: this.$t('amount_for_which_funding'),
          value: currency(this.propertyConditions.conditions_amount)
        },
        {
          text: this.$t('period_within_credit_application'),
          value: addDaysText(this.propertyConditions.conditions_term)
        }
      ];
    }
  },
  watch: {
    conditions(val) {
      if (val) this.toggleconditionsForm();
    }
  },
  created() {
    this.conditions = this.propertyConditions.conditions;
    this.conditionsSubmitted = this.propertyConditions.conditions; // When changed page after entering conditions data
    this.conditions_amount = this.propertyConditions.conditions_amount;
    this.conditions_term = this.propertyConditions.conditions_term;
  },
  methods: {
    addDaysText,
    blockPeriodInput,
    ...mapMutations('property', ['SET_PROPERTY_CONDITIONS']),
    incrementDays() {
      if (isNaN(this.conditions_term)) this.conditions_term = 11;
      else this.conditions_term++;
      this.$refs.daysInput.performValidation();
    },
    decrementDays() {
      if (isNaN(this.conditions_term)) this.conditions_term = 20;
      else this.conditions_term--;
      this.$refs.daysInput.performValidation();
    },
    hideConditionsForm() {
      this.conditionsFormShow = false;
      this.conditions = this.conditionsSubmitted;
    },
    showConditionsForm() {
      this.conditionsFormShow = true;
    },
    toggleconditionsForm() {
      if (this.conditionsFormShow) {
        this.hideConditionsForm();
      } else {
        this.showConditionsForm();
      }
    },
    submit(data) {
      data.conditions_term = this.conditions_term;
      this.SET_PROPERTY_CONDITIONS(data);
      this.conditionsSubmitted = true;
      this.toggleconditionsForm();
      let model = {
        type: 'success',
        messageBold: this.$t('success_conditions_message_bold'),
        message: ' ' + this.$t('success_conditions_message')
      };
      this.$store.dispatch('snackbar/show', model);
    }
  }
};
</script>
