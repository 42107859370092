var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.bidStatus === 2)?_c('BaseIconMessage',{key:"bidPending",attrs:{"border":false,"icon":"fa-rocket-launch","message":_vm.$t('bid_message_pending')}}):(_vm.bidStatus === 4)?_c('BaseIconMessage',{key:"bidSuccess",attrs:{"border":false,"icon":"fa-check-circle","message":_vm.$t('bid_message_success'),"text-color":"text-notification-success"}}):_c('FormulateForm',{key:"bidForm",attrs:{"name":"registerBid"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('PropertyBidForm'),_c('PropertyBuyers'),_c('FormulateInput',{attrs:{"type":"toggle","name":"accept_terms","validation":"required","validation-messages":{
        required: _vm.$t('validation_required_terms')
      },"label-position":"after","outer-class":"my-8"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"tg-mobile-body-small-bold lg:tg-desktop-body"},[_vm._v(" "+_vm._s(_vm.$t('i_agree_with_the'))+" "),_c('a',{staticClass:"link underline",attrs:{"href":"https://www.dewaele.com/nl/disclaimer","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('purchase_conditions'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('and_the'))+" "),_c('a',{staticClass:"link underline",attrs:{"href":_vm.$t('privacy_policy_property_link'),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('privacy_policy'))+" ")])])]},proxy:true}],null,true)}),_vm._v(" "+_vm._s(_vm.$t('bid_pre_submit_message'))+" "),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","help":_vm.formErrorHelp[0],"disabled":_vm.formErrorHelp.length > 0 || isLoading,"input-class":['w-full lg:w-auto']}},[_c('i',{class:[
          'far mr-2',
          isLoading ? 'fa-spinner-third animate-spin' : 'fa-gavel'
        ]}),_vm._v(" "+_vm._s(_vm.$t('submit_offer'))+" ")])]}}]),model:{value:(_vm.bid),callback:function ($$v) {_vm.bid=$$v},expression:"bid"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }