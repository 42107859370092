<template>
  <div>
    <button
      type="button"
      class="px-5 py-0.5 my-5 lg:my-6 mx-auto block tg-mobile-button-small lg:tg-desktop-body text-white bg-brand-primary rounded-full shadow-card hover:bg-opacity-84"
      @click="showBuyersForm"
    >
      <i class="far fa-user-plus mr-2" />
      {{ $t('add_buyer') }}
    </button>
    <transition name="fade" mode="out-in">
      <div
        v-if="buyersForm"
        class="w-full h-full fixed inset-0 z-40 grid place-items-center bg-overlay overflow-auto"
      >
        <div class="w-full max-w-xl bg-white rounded-lg cursor-auto">
          <div class="flex justify-between p-5 border-b border-gray-e9">
            <h4
              class="tg-mobile-header-4 lg:tg-desktop-header-4"
              v-text="$t('add_buyer')"
            />
            <button type="button" class="-m-4" @click="hideBuyersForm">
              <i class="p-4 far fa-times" />
            </button>
          </div>
          <FormulateForm
            #default="{ hasErrors }"
            v-model="buyer"
            name="propertyBuyersAddEdit"
            class="px-5"
            @submit="submit"
          >
            <FormulateInput
              type="toggle"
              name="company"
              :label="$t('label_company_toggle')"
              label-position="after"
            />
            <transition name="fade" mode="out-in">
              <fieldset v-if="buyer.company">
                <legend class="tg-mobile-header-5 lg:tg-desktop-header-5">
                  {{ $t('company_that_wants_to_buy') }}:
                </legend>
                <i>
                  {{ $t('fieldset_legend_buyer') }}
                  <span class="underline">{{ $t('together') }}</span>
                  {{ $t('buyer_company_details_message') }}
                </i>
                <FormulateInput
                  type="toggle"
                  name="required_toggle"
                  :label="$t('legally_authorised_to_represent')"
                  label-position="after"
                  validation="required"
                  :validation-messages="{
                    required: $t('you_need_to_accept_this')
                  }"
                  :show-required-label="true"
                />
                <FormulateInput
                  type="text"
                  name="company_name"
                  :label="$t('name_of_company')"
                  :show-required-label="true"
                  validation="required:trim"
                />
                <FormulateInput
                  type="select"
                  name="company_type"
                  :label="$t('corporate_form')"
                  :placeholder="$t('select_company_type')"
                  :options="$t('company_type_options')"
                  :show-required-label="true"
                  validation="required"
                />
                <FormulateInput
                  type="text"
                  name="company_vat"
                  :label="$t('ondernemingsnummer')"
                  :show-required-label="true"
                  validation="required:trim"
                />
                <FormulateInput
                  type="toggle"
                  name="company_vat_liable"
                  :label="$t('company_is_subject_to_vat')"
                  label-position="after"
                />
                <FormulateInput
                  type="text"
                  name="company_street"
                  :label="$t('street')"
                  :show-required-label="true"
                  validation="required:trim"
                />
                <div class="grid grid-flow-col gap-5 my-4">
                  <FormulateInput
                    type="text"
                    name="company_number"
                    :label="$t('nummer')"
                    :show-required-label="true"
                    validation="required:trim"
                    outer-class="m-0"
                  />
                  <FormulateInput
                    type="text"
                    name="company_box"
                    :label="$t('toevoeging')"
                    outer-class="m-0"
                  />
                </div>
                <FormulateInput
                  type="autocomplete"
                  name="company_city"
                  :label="$t('location')"
                  :placeholder="$t('placeholder_autocomplete')"
                  validation="required"
                  :show-required-label="true"
                />
              </fieldset>
            </transition>
            <fieldset>
              <legend
                v-if="buyer.company"
                class="tg-mobile-header-5 lg:tg-desktop-header-5"
              >
                {{ $t('represented_by') }}:
              </legend>
              <FormulateInput
                type="text"
                name="first_name"
                :label="$t('first_name')"
                :show-required-label="true"
                validation="required:trim"
              />
              <FormulateInput
                type="text"
                name="last_name"
                :label="$t('last_name')"
                :show-required-label="true"
                validation="required:trim"
              />
              <FormulateInput
                type="select"
                name="language"
                :label="$t('preferred_language')"
                :placeholder="$t('select_language')"
                :value="$i18n.locale"
                :options="langOptions"
                :help="$t('buyer_locale_help_text')"
                :show-required-label="true"
                validation="required"
              />
              <FormulateInput
                v-model.trim="buyer.email"
                type="email"
                name="email"
                :label="$t('email_label')"
                :show-required-label="true"
                validation="bail|required|email"
              />
              <FormulateInput
                type="dial_code_tel"
                name="mobile"
                :label="$t('mobile')"
                :help="$t('buyer_mobile_help')"
                :show-required-label="true"
                validation="bail|required|phone"
              />
              <FormulateInput
                type="dial_code_tel"
                name="phone"
                :label="$t('phone')"
                validation="bail|optional|phone"
              />
              <FormulateInput
                type="text"
                name="national_number"
                :label="$t('national_insurance_number')"
                placeholder="______ ___.__"
                :show-required-label="true"
                validation="required:trim"
              />
              <!-- minEighteenYears is set as the value so that the calendar starts at the minimum eligible date -->
              <FormulateInput
                v-model.trim="buyer.birth_date"
                type="date"
                name="birth_date"
                :label="$t('birth_date')"
                placeholder="YYYY-MM-DD"
                :value="minEighteenYears.date"
                :max="minEighteenYears.date"
                :show-required-label="true"
                :validation="[
                  ['bail'],
                  ['required'],
                  ['date', 'YYYY-MM-DD'],
                  ['before', minEighteenYears.forValidation]
                ]"
              />
              <FormulateInput
                type="text"
                name="birth_place"
                :label="$t('birthplace')"
                :show-required-label="true"
                validation="required:trim"
              />
              <FormulateInput
                type="text"
                name="street"
                :label="$t('street')"
                :show-required-label="true"
                validation="required:trim"
              />
              <div class="grid grid-flow-col gap-5 my-4">
                <FormulateInput
                  type="text"
                  name="number"
                  :label="$t('nummer')"
                  :show-required-label="true"
                  validation="required:trim"
                  outer-class="m-0"
                />
                <FormulateInput
                  type="text"
                  name="box"
                  :label="$t('box')"
                  outer-class="m-0"
                />
              </div>
              <FormulateInput
                type="autocomplete"
                name="city"
                :label="$t('location')"
                :placeholder="$t('placeholder_autocomplete')"
                :show-required-label="true"
                validation="required"
              />
            </fieldset>
            <FormulateInput
              type="submit"
              :input-class="['w-full lg:w-auto']"
              outer-class="mt-3.5 mb-8"
              :disabled="hasErrors"
              :help="hasErrors ? $t('form_not_valid') : ''"
              help-position="before"
            >
              <i class="far fa-user-plus mr-2" />
              {{ $t('add_buyer') }}
            </FormulateInput>
          </FormulateForm>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { minEighteenYears, langOptions } from '@/helpers';
import { mapMutations } from 'vuex';

export default {
  name: 'PropertyBuyersAddEdit',
  props: {
    selectedBuyer: {
      type: Object
    }
  },
  data() {
    return {
      minEighteenYears,
      langOptions,
      buyer: {},
      buyersForm: false
    };
  },
  watch: {
    'selectedBuyer.edit'(val) {
      if (val) {
        this.buyer = { ...this.selectedBuyer.buyer };
        this.showBuyersForm();
      }
    }
  },
  methods: {
    ...mapMutations('property', ['ADD_PROPERTY_BUYER', 'EDIT_PROPERTY_BUYER']),
    hideBuyersForm() {
      this.buyersForm = false;
      this.$emit('close');
    },
    showBuyersForm() {
      this.buyersForm = true;
    },
    submit(data) {
      let buyer = { ...data },
        model = {
          type: 'success',
          messageBold: buyer.company_name || buyer.first_name
        };

      if (this.selectedBuyer.edit) {
        this.EDIT_PROPERTY_BUYER({ buyer, index: this.selectedBuyer.index });
        model.message = this.$t('buyer_updated');
      } else {
        this.ADD_PROPERTY_BUYER(buyer);
        model.message = this.$t('buyer_added');
      }

      this.hideBuyersForm();
      this.$store.dispatch('snackbar/show', model);
    }
  }
};
</script>
